import { useState } from "react";
import { useContext } from "react";


import Lightbox from "yet-another-react-lightbox";
// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import { BooksContext } from "../../contexts/books.context";
import AddToCartButton from "../../components/add-to-cart-button/add-to-cart-button.componenet";


import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";


import { BookDescription, BookHeader, BookImage, BookHeaderDescription, BookPrice } from "../egyensuly/egyensuly.styles";



const photos = [
	{ src: "/img/konyvek/Dr_Goblyos_Istvan_Tizenot_mondat_lovaskonyv_mintaoldal_006-007.jpg", width: 1200, height: 600 },
	{ src: "/img/konyvek/Dr_Goblyos_Istvan_Tizenot_mondat_lovaskonyv_mintaoldal_026-027.jpg", width: 1200, height: 600 },
	{ src: "/img/konyvek/Dr_Goblyos_Istvan_Tizenot_mondat_lovaskonyv_mintaoldal_046-047.jpg", width: 1200, height: 600 },
	{ src: "/img/konyvek/Dr_Goblyos_Istvan_Tizenot_mondat_lovaskonyv_mintaoldal_056-057.jpg", width: 1200, height: 600 },
	{ src: "/img/konyvek/Dr_Goblyos_Istvan_Tizenot_mondat_lovaskonyv_mintaoldal_088-089.jpg", width: 1200, height: 600 },
	{ src: "/img/konyvek/Dr_Goblyos_Istvan_Tizenot_mondat_lovaskonyv_mintaoldal_098-099.jpg", width: 1200, height: 600 },
];


const FifteenProposition = () => {

	const { getBook } = useContext(BooksContext);
	const [index, setIndex] = useState(-1);

	const mybook = getBook(2);
	
	return (
		
		<div>
			<BookDescription className="content_inner">
					<BookHeader>
						<BookImage>
						<img src="/img/konyvek/Dr_Goblyos_Istvan_Tizenot_mondat_lovaskonyv_kezben.jpg" alt="tizenot mondat" />
						</BookImage>
						<BookHeaderDescription>
						<h2>{mybook.title}</h2>
						<h3>Alapelvek a helyes lókiképzéshez.</h3>
						<h3> Az Egyensúly, elengedettség, egyenesség című első kötet folytatása</h3>
							<BookPrice>{mybook.price} Ft</BookPrice>
							<AddToCartButton key={mybook.id} book={mybook}></AddToCartButton>
						</BookHeaderDescription>


					</BookHeader>
				
				<p>
					
						
				</p>
				<p>
					
				</p>

				<div className="container">
					<div className="box">
						<div className="box_image">
							<img src="/img/konyvek/Dr_Goblyos_Istvan_Tizenot_mondat_lovaskonyv_mintaoldal_006-007_thumbnail.webp" onClick={() => setIndex(0)} alt="EGYENSÚLY, ELENGEDETTSÉG, EGYENESSÉG" />
						</div>
						<div className="box_description">
							<p>
								Az ország egyik elismert lókiképzője, dr. Gőblyös István, második könyvében,
								könnyen megérthető formában foglalja össze a lókiképzés legfontosabb alapelveit.
							</p>
						</div>
					</div>
					<div className="box">
						<div className="box_image">
							<img src="/img/konyvek/Dr_Goblyos_Istvan_Tizenot_mondat_lovaskonyv_mintaoldal_026-027_thumbnail.webp" onClick={() => setIndex(1)} alt="EGYENSÚLY, ELENGEDETTSÉG, EGYENESSÉG" />
						</div>
						<div className="box_description">
							<p>
								A Tizenöt mondat az Egyensúly, Elengedettség, Egyenesség című könyv folytatása.
								Míg az első könyv a lókiképzés alapfogalmait foglalja össze, hogy az olvasóknak
								helyes elképzelésük alakuljon ki a lóval való foglalkozás egyes kérdéseiről,
								addig jelen második kötet azt az alapállást, azt az alapszemléletet segíti kifejleszteni,
								ami a helyes lókiképzés során elengedhetetlen.
							</p>
						</div>
					</div>
					<div className="box">
						<div className="box_image">
							<img src="/img/konyvek/Dr_Goblyos_Istvan_Tizenot_mondat_lovaskonyv_mintaoldal_046-047_thumbnail.jpg" onClick={() => setIndex(2)} alt="EGYENSÚLY, ELENGEDETTSÉG, EGYENESSÉG" />
						</div>
						<div className="box_description">
							<p>
								A lókiképzés alapelveinek ismerete a ló képzettségének minősítéséhez, elfogultságtól
								mentes értékeléséhez adnak támpontokat, fogódzkodót. Segítséget ad a kiképzés egyes
								lépéseinek megtervezésére, megvalósítására, a kiképzés során adódó problémák azonosítására,
								kijavításukra, akár a lovas számára ismeretlen, még meg nem tapasztalt helyzetekben is.
							</p>
						</div>
					</div>
					<div className="box">
						<div className="box_image">
							<img src="/img/konyvek/Dr_Goblyos_Istvan_Tizenot_mondat_lovaskonyv_mintaoldal_056-057_thumbnail.webp" onClick={() => setIndex(3)} alt="EGYENSÚLY, ELENGEDETTSÉG, EGYENESSÉG" />
						</div>
						<div className="box_description">
							<p>
								Az alapelvek ismerete nélkül lehetetlen a lókiképzés, azok ismeretével viszont a
								csúcsokig el lehet jutni.
							</p>
						</div>
					</div>
					<div className="box">
						<div className="box_image">
							<img src="/img/konyvek/Dr_Goblyos_Istvan_Tizenot_mondat_lovaskonyv_mintaoldal_088-089_thumbnail.webp" onClick={() => setIndex(4)} alt="EGYENSÚLY, ELENGEDETTSÉG, EGYENESSÉG" />
						</div>
						<div className="box_description">
							<p>
								A könyv a lókiképzés alapelveinek ismereteibe könnyen követhető módon,
								látványos képekkel vezeti be az olvasót.
							</p>
						</div>
					</div>
					<div className="box">
						<div className="box_image">
							<img src="/img/konyvek/Dr_Goblyos_Istvan_Tizenot_mondat_lovaskonyv_mintaoldal_098-099_thumbnail.webp" onClick={() => setIndex(5)} alt="EGYENSÚLY, ELENGEDETTSÉG, EGYENESSÉG" />
						</div>
						<div className="box_description">
							<p>
								Forgatása – az első kötethez hasonlóan – nem csak a lovasoknak, hanem szüleiknek, barátaiknak,
								családtagjaiknak is ajánlott, hogy jobban megértsék barátaik,
								családtagjaik szenvedélyét, vagy csupán gyönyörködjenek a kiadvány
								páratlan lovas fotóiban.
							</p>
						</div>
					</div>
				</div>
			</BookDescription>
			<Lightbox
				slides={photos}
				open={index >= 0}
				index={index}
				close={() => setIndex(-1)}
				// enable optional lightbox plugins
				plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
			/>

		</div>
	)
}

export default FifteenProposition;