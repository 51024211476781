import styled from 'styled-components';



export const CheckoutContainer = styled.div`
	margin-top: 100px;
	@media (max-width: 500px) {
		margin-top: 50px;
	}
	
`

export const CartContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	@media (max-width: 1550px) {
		flex-direction: column;
	}

`
export const CartLines = styled.div`
	
`

export const CheckoutButton = styled.div`
  padding-left: 5px;
	padding-right: 5px;
  cursor: pointer;
	display: inline-block;
	font-weight: bold;
	border: 1px solid #2d2d2d;
	border-radius: 15px;
	line-height: 25px;
	margin: 0 10px;
`;

export const CartLine = styled.div`
	display: block;
	clear: both;
	overflow: hidden;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: 1px solid #2d2d2d;
	width: 100%;
	padding-bottom: 20px;
	margin-bottom: 20px;
	@media (max-width: 767.9px) {
		display: block;
		clear: both;
	}
`

export const CartTitle = styled.div`
/* Office ipsum you must be muted. Site fured scraps quarter standup get drawing-board timepoint base. */

	width: 100%;
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 33px;
	padding-left: 15px;
	padding-right: 60px;
	text-align: left;


	h3{
	font-family: 'Manuale';
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	line-height: 100%;
	text-transform: capitalize;
	}
	@media (max-width: 767.9px) {
		display: block;
		width: 100%;
		

		h3{
			font-size: 28px;
		}


	}
	@media (max-width: 600px) {
		float: left;
		h3{
			font-size: 28px;;
		}
	}

`

export const CartImage = styled.div`
	float: left;
	margin-right: 20px;
	img{
		width: 180px;
		height: auto;

	}
	
`

export const CartPrice = styled.div`
	text-align: right;
	min-width: 170px;
	float: right;
	@media (max-width: 767.9px) {
		min-width: 20px;
	}
`

export const CartItemPrice = styled.div`


font-family: 'Manuale';
font-style: normal;
font-weight: 700;
font-size: 42px;
line-height: 100%;
/* or 42px */


color: #2D2D2D;
@media (max-width: 500px) {
	font-size: 28px;
}


`

export const CartSummary = styled.div`
	margin-left: 60px;
	h2{

		font-family: 'Manuale';
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 46px;

	}

	@media (max-width: 1550px) {
		margin-left: 0px;
	}

	@media (max-width: 600px) {
		h2{
			font-size: 24px;
		}
	}

	.sub-container {
		border: 2px solid #2D2D2D;
		border-bottom: 0;
		padding: 40px;
		min-height: 200px;
		@media (max-width: 600px) {
			padding: 5px;
		}

	}

.upper-zigzag {
  background: linear-gradient(135deg, #ffffff 25%, transparent 25%) 0px 0,
    linear-gradient(225deg, #ffffff 25%, transparent 25%) 0px 0;
  background-size: 30px 30px;
  background-color: #2D2D2D;
  height: 17px;
  background-repeat: repeat-x;
  border-left: 2px solid #2D2D2D;
  border-right: 2px solid #2D2D2D;
}

.lower-zigzag {
  position: relative;
  background:
    linear-gradient(315deg, #ffffff 25%, transparent 25%) -14px -15px,
    linear-gradient(45deg, #ffffff 25%, transparent 25%) -14px -15px;
  background-size: 30px 30px;
  background-color: transparent;
  height: 15px;
  background-repeat: repeat-x;
  margin-top: -15px;
  z-index: 1;
}
`

export const SummaryRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	min-width: 400px;
	width: 100%;
	font-family: 'Manuale';
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 180%;

color: #2D2D2D;

	@media (max-width: 600px) {
		min-width: 300px;
	}
`

export const SummaryCol = styled.div`
	flex: 1;
	&.price{
		text-align: right;
	}
`


export const SummaryTotal = styled.div`
	font-family: 'Manuale';
	font-style: normal;
	font-weight: 600;
	font-size: 44px;
	line-height: 115.68%;

`

