import Carousel from 'react-bootstrap/Carousel';
import { useContext } from "react";
import { BooksContext } from "../../contexts/books.context";


import BookCarouselItem from './book-front.carousel-item.component';
import { BookCarouselStyled} from './books-front.styles';



const BookCarousel = () => {

	const { books } = useContext(BooksContext); 


	return (
		<BookCarouselStyled className='content_inner'>
			<Carousel slide={false} indicators={false} variant="dark">
				{books.map((book) => (

					<Carousel.Item key={book.id}>
						<BookCarouselItem key={book.id} book={book} />
					</Carousel.Item>
				))}
			</Carousel>
		</BookCarouselStyled>
		
	);
}

export default BookCarousel;