import styled from 'styled-components';
import { Link } from "react-router-dom";

export const FooterContainer = styled.footer`
	background-color: #2d2d2d;
	color: #fff;
	font-size: 18px;
	
	position: relative;
	bottom: 0px;
	width: 100%;
	min-height: 127px;
	margin-top: 30px;

`;

export const FooterInner = styled.div`
	max-width: 1600px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 127px;
	@media (max-width: 768px) {
		flex-direction: column-reverse;
		padding: 30px;
	}
`
export const FooterText = styled.div`
	display: inline-flex;
	align-items: center;
	
	width: auto;
	@media (max-width: 768px) {
		width: 100%;
		text-align: center;
	}

`

export const FooterIcons = styled.div`

	display: inline-flex;
	align-items: center;
		@media (max-width: 768px) {
			width: 100%;
			text-align: center;
			display: flex;
			justify-content: center;
		}

	
`

export const FooterIcon = styled(Link)`
	cursor: pointer;
	
	img{
		height: 34px;
		width: auto;
	}
	&:last-of-type{
		border: none;
	}
	padding: 10px;

`