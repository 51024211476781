import { useContext } from 'react';
import { CartContext } from '../../contexts/cart.context';



import PaymentForm from '../../components/payment-form/payment-form.component';

import {
		CheckoutContainer, CheckoutButton,
	 	CartLines, CartLine, CartPrice, CartTitle, CartImage, CartContainer, CartItemPrice,
		CartSummary, SummaryRow, SummaryCol, SummaryTotal} from './checkout.styles';



const CheckoutItem = ({checkoutItem}) => {
	const { title, quantity, price, imageUrl, description } = checkoutItem;
	

	const { addItemToCart, decreaseItemQty} = useContext(CartContext);
	const increaseItem = () => addItemToCart(checkoutItem);
	const decreaseItem = () => decreaseItemQty(checkoutItem);


	return (
		<CartLine>
			
			<CartPrice>
				<CartItemPrice>{price} Ft</CartItemPrice><br />
				<CheckoutButton onClick={decreaseItem}>	&minus;</CheckoutButton>
				{quantity}
				<CheckoutButton onClick={increaseItem}>	+ </CheckoutButton>
			</CartPrice>
			<CartImage><img src={process.env.PUBLIC_URL + '/img/' + imageUrl} alt={`${title}`} /></CartImage>
			<CartTitle>
				<h3>{title}</h3>
			{description}
			</CartTitle>
			
		</CartLine>
	)
}


const Checkout = () => {

	const { cartItems , cartTotalValue, cartItemsCount} = useContext(CartContext);
	
	return (
		<CheckoutContainer className='content_inner'>
			<CartContainer>
				<CartLines>
					{cartItems.map((cartItem) => (
						<CheckoutItem checkoutItem={cartItem} key={cartItem.id}></CheckoutItem>
					))}
				</CartLines>
				<CartSummary>
				
					<div className="sub-container">
						<h2>Rendelés összegzése:</h2>
						<SummaryRow>
							<SummaryCol>Termékek ára:</SummaryCol>
							<SummaryCol className='price'>{cartTotalValue} Ft</SummaryCol>
						</SummaryRow>
						<SummaryRow>
							<SummaryCol>Postaköltség ára:</SummaryCol>
							<SummaryCol className='price'>2510 Ft</SummaryCol>
						</SummaryRow>
						 <br/>
						<h2>Kifizetendő összeg:</h2> 
						<SummaryTotal>
							{cartTotalValue + ((cartItemsCount > 0)?2510:0)} Ft
						</SummaryTotal>
						
						</div>
					<div className="upper-zigzag"></div>
					<div className="lower-zigzag"></div>
				</CartSummary>	
			</CartContainer>
			
			<PaymentForm></PaymentForm>
		</CheckoutContainer>
		
		
	)
}

export default Checkout;


