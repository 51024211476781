import { HeroStyled, HeroText, HeroTextTitle, HeroTextDetail, HeroStyledGradient, Book, Scroll } from "./hero.styles";



const Hero = () => {
	return(
		<HeroStyled>
			<HeroStyledGradient>
		
			<div className="content_inner">
				<HeroText to="/konyvek">
					<HeroTextTitle>
						Már mindhárom könyv megrendelhető!
					</HeroTextTitle>
					<HeroTextDetail>
					<br/>
						Dr. Boór-Gőblyös István lókiképző.
					</HeroTextDetail>
				</HeroText>
				<Book>
						<img src="/img/konyvek/Dr_Boor_goblyos_Istvan_Vizio_Erzes_Technika_3d.webp" alt="Vizio Erzes Technika" />
				</Book>
				<Scroll>
					<img src="/img/Union.png" alt="Scroll down" />
				</Scroll>
			</div>
			</HeroStyledGradient>
		</HeroStyled>
		
	)
}

export default Hero;