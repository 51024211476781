import styled from "styled-components";

export const BooksContainer = styled.div`
	margin-top: 70px;
	display: grid;

	@media (min-width: 1100px) {
		grid-template-columns: repeat(3, 1fr);
	}
	grid-template-columns: repeat(1, 1fr);
	column-gap: 20px;
	row-gap: 70px;
`;
