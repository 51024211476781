import { Link } from "react-router-dom";

import { AuthorStyled, AuthorContainer, AuthorImage } from "./szerzo.styles";

const Author = () => {
	return (
		<AuthorContainer className="content_inner">
			<AuthorStyled>
				<AuthorImage>
					<img src="/img/Goblyos_a_szerzo.webp" alt="Dr. Boór-Gőblyös István lókiképző, lovasoktató"></img>
				</AuthorImage>
				<h2>Dr. Boór-Gőblyös István</h2>
				<h3>lókiképző, lovasoktató</h3>
				<p></p>
				<p>
					Dr. Boór-Gőblyös István  negyven éve foglalkozik
					lovakkal, tizenöt éve a Kajászó-Szentpéterpuszta Lovarda tulajdonosa, lovasedző, lókiképző.
				</p>
				<p>
					Dallos Gyula legidősebb tanítványa, díjlovaglásban, militaryban és díjugratásban versenyzett. 2014 óta az
					Academic Art of Ridingnak a tagja, 2013-tól vezeti saját iskoláját. 20 éve foglalkozik a lókiképzés
					elméletével, kutatja az elmúlt századok mestereinek lovaglási technikáját.
				</p>
				<p>
					Évek óta azon fáradozik, hogy újraélessze a magyar lókiképzés akadémista stílusát. Elkötelezett abban,
					hogy nemzeti hagyományainkat a XXI. századi lókiképzés részévé tegye, klasszikus lovas kultúránkat
					feltámassza.
				</p>
				<p>
					Rendszeresen publikál lovas szaklapokban, <Link to="https://zablaeskengyel.blog.hu/">“Zabla és Kengyel”</Link> 
					nevű blogján lévő cikkeit pedig 2010 óta több százezer alkalommal olvasták.
				</p>
				<p>
					Első könyve az Egyensúly, Elengedettség, Egyenesség nem pusztán az egyes fogalmak magyarázatát,
					 hanem a magyar lovas szakszöveg magas szintű művelését, terjesztését, annak részbeni megújítását szolgálja.
					  Azoknak az igényes lovasoknak íródott, akik klasszikus elvekre szeretnék alapozni tudásukat, 
						akik helyes lovasérzéssel szeretnének lovagolni: 
						kezdők és haladók számára, mindenkinek, aki szeretne lovával egyről-kettőre jutni.
				</p>

				
			</AuthorStyled>
			
			
		</AuthorContainer>
	);
}

export default Author;