import styled from "styled-components";


export const ASZF = styled.div`

h2,h1{


	font-family: 'Manuale';
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	line-height: 100%;
	/* or 36px */

	color: #2D2D2D;
	margin: 30px 0;

}

h1{
	font-size: 40px;
}
`