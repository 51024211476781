import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from '../../contexts/cart.context';

import InvertedButton from '../button/button.component';
import CartItem from '../cart-item/cart-item.component';

import {CartDropdownContainer, CartItemsContainer, EmptyMessage} from './cart-dropdown.styles';

const CartDropdown = () => {
	const { cartItems, isCartOpen, setIsCartOpen } = useContext(CartContext);

	const toggleIsCartOpen = () => setIsCartOpen(!isCartOpen);

	return (
		<CartDropdownContainer>
			<CartItemsContainer>

			{cartItems.length
				?
					(cartItems.map((item) => (
						<CartItem cartItem={item} key={item.id}></CartItem>
					)))
				:
					<EmptyMessage>A kosár üres</EmptyMessage>
			}
			</CartItemsContainer>
			{cartItems.length
				? <Link to="/checkout" onClick={toggleIsCartOpen}><InvertedButton>Rendelés</InvertedButton></Link>
				: <span></span>
			}
		</CartDropdownContainer>
	)
}

export default CartDropdown;
