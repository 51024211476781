import { createContext, useState, useEffect } from "react";

const addCartItem = (cartItems, bookToAdd) => {
	const existingCartItem = cartItems.find(
		(cartItem)=>cartItem.id === bookToAdd.id 
	);

	if(existingCartItem){
		return cartItems.map((cartItem) => cartItem.id === bookToAdd.id ?
			{...cartItem,quantity: cartItem.quantity + 1 }:
			cartItem
		)
	}
	return [...cartItems,{...bookToAdd, quantity: 1}];
}

const decreaseCartItem = (cartItems, bookToDecrease) => {
	const existingCartItem = cartItems.find(
		(cartItem) => cartItem.id === bookToDecrease.id
	);

	if (existingCartItem.quantity > 1) {
		return cartItems.map((cartItem) => cartItem.id === bookToDecrease.id ?
			{ ...cartItem, quantity: cartItem.quantity - 1 } :
			cartItem
		)
	}else{
		return cartItems.filter(cartItem => {
			// 👇️ remove object that has id equal
			return cartItem.id !== bookToDecrease.id;
		});
	}
}




export const CartContext = createContext({
	isCartOpen: false,
	isModalOpen: false,
	setIsCartOpen: () => {},
	cartItems: [],
	addItemtoCart: () => {},
	cartItemsCount: 0,
	cartTotalValue:0,
	cartShortContent: '',
});


export const CartProvider = ({children}) => {
	const [isCartOpen, setIsCartOpen] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [cartItems, setCartItems] = useState([]);
	const [cartItemsCount, setCartItemsCount] = useState(0);
	const [cartTotalValue,setCartTotalValue] = useState(0);
	const [cartShortContent, setCartShortContent] = useState('');

	useEffect(()=>{
		const newCartCount = cartItems.reduce((totalQty, currentItem) => totalQty + currentItem.quantity, 0);
		setCartItemsCount(newCartCount);

	},[cartItems]);

	useEffect(() => {
		const newCartTotal = cartItems.reduce((totalValue, currentItem) => totalValue + currentItem.quantity * currentItem.price, 0);
		setCartTotalValue(newCartTotal);

	}, [cartItems]);

	useEffect(() => {
		const newShortCartContent = cartItems.reduce((totalContent, currentItem) => totalContent + currentItem.title + ' ' + currentItem.quantity + 'db |', 0);
		setCartShortContent(newShortCartContent);
	}, [cartItems]);


	const addItemToCart = (bookToAdd) => {
		setCartItems(addCartItem(cartItems,bookToAdd));
		setIsModalOpen(true);
	}

	const decreaseItemQty = (bookToDecrease) => {
		setCartItems(decreaseCartItem(cartItems, bookToDecrease));
	}

	const emptyItems = () => {
		setCartItems([]);
	}

	const removeCartItem = (itemToRemove) => {
		setCartItems(cartItems =>
			cartItems.filter(cartItem => {
				// 👇️ remove object that has id equal
				return cartItem.id !== itemToRemove.id;
			}),
		);
	}

	const value = { isCartOpen, setIsCartOpen, isModalOpen, setIsModalOpen, 
		cartItems, addItemToCart, cartItemsCount, removeCartItem, decreaseItemQty,cartTotalValue,
		cartShortContent, emptyItems};
	return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};