// import { useState } from 'react';
// import { Modal } from "react-bootstrap"

import {BaseButton, InvertedButton, GoogleSignInButton, CheckoutButton, LoadingSpinner} from "./button.styles";

export const BUTTON_TYPE_CLASSES = {
	base: 'base',
	google: 'google-sign-in',
	inverted: 'inverted',
	checkout: 'checkout',
};

const getButton = (buttonType = BUTTON_TYPE_CLASSES.base) => (
	{
		[BUTTON_TYPE_CLASSES.base]:BaseButton,
		[BUTTON_TYPE_CLASSES.google]: GoogleSignInButton,
		[BUTTON_TYPE_CLASSES.inverted]: InvertedButton,
		[BUTTON_TYPE_CLASSES.checkout]: CheckoutButton,
	}[buttonType]
)

const Button = ({ children, buttonType, isLoading = false, ...otherProps }) => {
	const CustomButton = getButton(buttonType);
	return (
		<CustomButton disabled={isLoading}{...otherProps}>
			{isLoading ? <LoadingSpinner /> : children}</CustomButton>
	);
};


// export const AddToCartButton = ({ children, ...otherProps }) => {

// 	const [show, setShow] = useState(false);

// 	const handleClose = () => setShow(false);
// 	const handleShow = () => setShow(true);

// 	return (
// 		<div>
// 			<Button
// 				buttonType={BUTTON_TYPE_CLASSES.inverted}
// 				className="add_to_cart" 
// 				{...otherProps}>
// 					<img src='/bag-plus.png' alt='Megvásárolom' />
// 				{children}
// 			</Button>
// 			<Modal show={show} onHide={handleClose}>
// 				<Modal.Header closeButton>
// 					<Modal.Title>Modal heading</Modal.Title>
// 				</Modal.Header>
// 				<Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
// 				<Modal.Footer>
// 					<Button variant="secondary" onClick={handleClose}>
// 						Close
// 					</Button>
// 					<Button variant="primary" onClick={handleClose}>
// 						Save Changes
// 					</Button>
// 				</Modal.Footer>
// 			</Modal>
// 		</div>
// 	)
// }

export default Button;

