import { useContext } from 'react';
import { Link } from 'react-router-dom';

import Button, { BUTTON_TYPE_CLASSES } from '../button/button.component';
import { CartContext } from '../../contexts/cart.context';


import { BookCarouselItemStyled, BookCarouselImage, BookCarouselDescription } from './books-front.styles';



const BookCarouselItem = ({ book }) => {
	const { title, route, imageUrl, description } = book;
	const { addItemToCart } = useContext(CartContext);
	const addBookToCart = () => addItemToCart(book);


	return (
		<BookCarouselItemStyled>
			<BookCarouselImage>
				<Link to={route}>
					<img src={process.env.PUBLIC_URL + '/img/' + imageUrl} alt={`${title}`} />
				</Link>
			</BookCarouselImage>
			<BookCarouselDescription>
				<h3>{title}</h3>
				{description}
				<Button buttonType={BUTTON_TYPE_CLASSES.base} onClick={addBookToCart}><img src='/bag-plus.png' alt='Megvásárolom' />Megvásárolom</Button>
			</BookCarouselDescription>
			
		</BookCarouselItemStyled>
	)

}

export default BookCarouselItem;

