import { Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';

import Home from './routes/home/home.component';
import Navigation from './routes/navigation/navigation.component';
import FifteenProposition from './routes/15-mondat/15-mondat.component';
import Egyensuly from './routes/egyensuly/egyensuly.component';
import Books from './routes/books/books.component';
import Checkout from './routes/checkout/checkout.component';
import Lovarda from './routes/lovarda/lovarda.component';
import Vizio from './routes/vizio/vizio.component';
import AlatanosFeltetelek from './routes/altalanos-feltetelek/altalanos-feltetelek.component';
import Author from './routes/szerzo/szerzo.component';
import TagManager from 'react-gtm-module'


import './App.css';





function App() {
	useEffect(() => {
		TagManager.initialize({ gtmId: 'G-NRS1V5KBD5' });
	}, []);

  return (
		<Routes>
			<Route path="/" element={<Navigation />} >
				<Route index element={ <Home />} />
				<Route path='konyvek' element={<Books />} />
				<Route path='lovarda' element={<Lovarda />} />
				<Route path='checkout' element={<Checkout />} />
				<Route path='egyensuly-elengedettseg-egyenesseg' element={<Egyensuly />} />
				<Route path="15-mondat" element={<FifteenProposition />} />
				<Route path="vizio-erzes-technika" element={<Vizio />} />
				<Route path="altalanos-szerzodesi-feltetelek" element={<AlatanosFeltetelek />} />
				<Route path="szerzo" element={<Author />} />
			</Route>
				
		</Routes>

  );
}

export default App;
