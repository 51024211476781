import { Modal } from "react-bootstrap"
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'

import InvertedButton from '../button/button.component';
import CartItem from "../cart-item/cart-item.component";

import { CartContext } from '../../contexts/cart.context';

import { CartDialog, CartItemsContainer, EmptyMessage } from './cart-dialog.styles';

const CartDialogModal = () => {
	const { cartItems, isModalOpen, setIsModalOpen } = useContext(CartContext);


	const handleClose = () => setIsModalOpen(false);
	const location = useLocation();
	if(isModalOpen && location.pathname === '/checkout'){
		setIsModalOpen(false);
	}

	return (
		<CartDialog>
		<style>
			border-radius: 0px;
		</style>
			<Modal show={isModalOpen} onHide={handleClose} >
				<Modal.Header closeButton>
					<Modal.Title>Egy új termék került be a kosaradba.</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<CartItemsContainer>
							{cartItems.length
								?
								(cartItems.map((item) => (
									<CartItem cartItem={item} key={item.id}></CartItem>
								)))
								:
								<EmptyMessage>A kosár üres</EmptyMessage>
							}
						</CartItemsContainer>
					
					</Modal.Body>
					<Modal.Footer>
						{cartItems.length
						? <Link to="/checkout" ><InvertedButton onClick={handleClose}>Kosár megtekintés/Rendelés</InvertedButton></Link>
							: <span></span>
						}
					</Modal.Footer>
			</Modal>
		</CartDialog>
	)
}

export default CartDialogModal;