import styled from "styled-components";


export const LovardaHeader = styled.div`
	margin-top: -1px;
	width: 100%;
	img{
		width:100%;
	}
	
`

export const LovardaBody = styled.div`
	margin-top: 100px;
	h1{
		font-family: 'Playfair Display SC';
		font-style: normal;
		font-weight: 700;
		font-size: 36px;
		line-height: 100%;
		color: #2D2D2D;
		margin-bottom: 30px;
		text-align: center;
	}
	h2{
		font-family: 'Manuele';
		font-style: normal;
		font-weight: 400;
		font-size: 36px;
		line-height: 100%;
		color: #2D2D2D;
		margin-bottom: 30px;
	}
	@media (max-width: 1100px) {
		margin-top: 50px;
		h1{
			font-size: 28px;
		}
		h2{
			font-size: 28px;
		}
	}
	@media (max-width: 768px) {
		h1{
			font-size: 28px;
		}
		h2{
			font-size: 20px;
		}
		font-size: 20px;
		text-align: left;
	}
	
`