
import { useContext } from 'react';


import { CartContext } from '../../contexts/cart.context';

import {CartIconContainer, ShoppingIcon, ItemsCount} from './cart-icon.styles';

const CartIcon = () => {
	const { isCartOpen, setIsCartOpen, cartItemsCount } = useContext(CartContext);

	const toggleIsCartOpen = () => setIsCartOpen(!isCartOpen);
	return (
		<CartIconContainer onClick={toggleIsCartOpen}>
			<ShoppingIcon />
			<ItemsCount>{cartItemsCount}</ItemsCount>
		</CartIconContainer>
	);
};

export default CartIcon;
