import { useContext } from 'react';

import { BUTTON_TYPE_CLASSES } from '../button/button.component';
import { CartContext } from '../../contexts/cart.context';

import { BaseButton } from './add-to-cart-button.styles';

const AddToCartButton = ({ book }) => {
	const { addItemToCart } = useContext(CartContext);
	const addBookToCart = () => addItemToCart(book);


	return (
		<BaseButton buttonType={BUTTON_TYPE_CLASSES.base} onClick={addBookToCart}><img src='/bag-plus.png' alt='Megvásárolom' />Megvásárolom</BaseButton>
	)

}

export default AddToCartButton;