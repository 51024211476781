import styled from "styled-components";

export const BaseButton = styled.button`
  min-width: 165px;
  width: auto;
  height: 70px;
  letter-spacing: 0.5px;
  line-height: 70px;
  padding: 0 35px 0 35px;
  font-size: 15px;
  background-color: black;
  color: white;
  text-transform: uppercase;
  font-family: 'Open Sans Condensed';
  font-weight: bolder;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;

	img{
			margin-top: 15px;
			width: 37px;
			height: 37px;
			margin-right: 15px;
			opacity: 1;
			
		}

  &:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
		img{
			-webkit-filter: invert(100%) !important;
		}
  }
`;