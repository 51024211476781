import styled from 'styled-components';
import { Link } from "react-router-dom";

export const HeroStyled = styled.div`
	margin-top: -1px;
	background-image: url('/img/goblyos_header.webp');
	background-size: cover;
	height: 800px;
	width: 100%;
	@media (max-width: 1600px) {
		height: 700px;;
	}
	@media (max-width: 1200px) {
		height: 500px;;
	}
	@media (max-width: 900px) {
		height: 450px;;
	}
	@media (max-width: 600px) {
		height: 300px;
		.content_inner{
			padding-left: 10px;
			padding-right: 10px;
			
		}
	}

`

export const HeroStyledGradient = styled.div`
	background: linear-gradient(0deg, rgba(45, 45, 45, 0.65) 0%, rgba(45, 45, 45, 0) 100%);
	width: 100%;
	height: 100%;
	.content_inner{
		display: block;
		/* justify-content: space-between; */
	}
	`;

/* Hero Text */
export const HeroText = styled(Link)`
	position: relative;
	display: block;
  top: 527px;
  max-width: 54%;
	mix-blend-mode: normal;
	@media (max-width: 1600px) {
		top: 400px;
	}
	@media (max-width: 1200px) {
		top: 300px;
	}
	@media (max-width: 900px) {
		top: 250px;
	}
	@media (max-width: 600px) {
		max-width: 100%;
		top:200px;
	}
`;


export const HeroTextDetail = styled.div`
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 30px;
	line-height: 41px;
	filter: drop-shadow(6px 8px 4px rgba(0, 0, 0, 0.25));
	color: #FFFFFF;
	@media (max-width: 1200px) {
		font-size: 25px;
	}
	@media (max-width: 900px) {
		font-size: 20px;
		line-height: 30px;
	}
	@media (max-width: 600px) {
		display: none;
	}
`;


export const HeroTextTitle = styled.div`
/* Már megrendelhető! */
font-family: 'Playfair Display SC';
font-style: italic;
font-weight: 700;
font-size: 65px;
line-height: 110.65%;
/* or 80px */
filter: drop-shadow(6px 8px 4px rgba(0, 0, 0, 0.25));
color: #FFFFFF;

	@media (max-width: 1700px) {
		font-size: 60px;
	}
	@media (max-width: 1600px) {
		font-size: 50px;
	}
	@media (max-width: 1200px) {
		font-size: 36px;
	}
	@media (max-width: 900px) {
		font-size: 28px;
	}
`;



export const Book = styled.div`
display: block;
margin-top:50px;
margin-left: 50px;
width: 43%;
float: right;
img{
	width: 100%;
	height: auto;
}
	@media (max-width: 1200px) {
		margin-top:0px;
	}
	@media (max-width: 900px) {
		margin-left: 0px;
		margin-top:25px;
		width: 50%;
	}

	@media (max-width: 600px) {
		margin-left: 0px;
		margin-top:75px;
		width: 50%;
	}

	@media (max-width: 500px) {
		margin-left: 0px;
		margin-top:200px;
		width: 70%;
		float: right;
	}
`

export const Scroll = styled.div`
	width: 20px;
	height: 42px;
	position: absolute;
	bottom: 15px;
	left: calc(50%);
	@media (max-width: 600px) {
		display: none;
	}
`

