
import styled from "styled-components";

export const CartDialog = styled.div`
	.modal-content{
		border-radius: 0px !important;
		
	}

`

export const CartItemsContainer = styled.div`
  height: 350px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
`;


export const EmptyMessage = styled.span`
  font-size: 18px;
  margin: 50px auto;
`;

