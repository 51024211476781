import styled from "styled-components";
import { Link } from "react-router-dom";

export const NavigationContainer = styled.div`
	height: 105px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	
	@media (max-width: 1100px) {
		height: 110px;
		display: block;
	}
	border-bottom: 1px solid #2d2d2d;
	@media (max-width: 500px) {
		height: 95px;
		&.content_inner{
			padding-left: 0px;
			padding-right: 0px;
		}
	}
`

export const LogoContainer = styled(Link)`
	height: 64px;
	width: auto;
	margin: auto 0;
	
	img{
		width: auto;
		height: 100%;
		display: inline-flex;
	}
	@media (max-width: 1100px) {
		height: 50px;
    width: 100%;
    margin: 0 auto;
    display: block;
    text-align: center;
		img{
			display: none;
		}
	}
	
`

export const LogoTitle=styled.div`
font-family: 'Open Sans', sans-serif;
	font-family: 'Playfair Display SC', serif;
	font-size: 30px;
	display: inline-flex;
	position: relative;
	margin-left: 22px;
	@media (max-width: 500px) {
		font-size: 22px;
	}
`

export const NavLinks = styled.div`
	width: 50%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-size: 14px;
	@media (max-width: 1100px) {
		margin-top:00px;
		height: 70px;
		width: 100%;
		
	}
	@media (max-width: 500px) {
		margin-top:00px;
		height: 40px;
		width: 100%;
		
	}
`

export const NavLink = styled(Link)`
	padding: 0 15px;
	margin: 10px 0;
	letter-spacing: 0.14em;
	text-transform: uppercase;
	color: #2D2D2D;
	font-size: 14px;


	cursor: pointer;
	border-right: 1px solid #2d2d2d;
	&:last-of-type{
		border: none;
	}

	@media (max-width: 600px) {
		padding: 0 10px;
		font-size: 13px;
	}
	@media (max-width: 500px) {
		padding: 0 10px;
		font-size: 10px;
	}
`
