import { useState } from "react";
import { useContext } from "react";


import Lightbox from "yet-another-react-lightbox";
// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import { BooksContext } from "../../contexts/books.context";
import AddToCartButton from "../../components/add-to-cart-button/add-to-cart-button.componenet";


import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";


import { BookDescription, BookHeader, BookImage, BookHeaderDescription, BookPrice } from "../egyensuly/egyensuly.styles";



const photos = [
	{ src: "/img/konyvek/VET_1.png", width: 2800, height: 1960 },
	{ src: "/img/konyvek/VET_2.png", width: 2800, height: 1960 },
	{ src: "/img/konyvek/VET_3.png", width: 2800, height: 1960 },
	{ src: "/img/konyvek/VET_4.png", width: 2800, height: 1960 },
	{ src: "/img/konyvek/VET_5.png", width: 2800, height: 1960 },
	
];


const FifteenProposition = () => {

	const { getBook } = useContext(BooksContext);
	const [index, setIndex] = useState(-1);

	const mybook = getBook(1);

	return (

		<div>
			<BookDescription className="content_inner">
				<BookHeader>
					<BookImage>
						<img src="/img/konyvek/Dr_Goblyos_Istvan_VET_kezben.webp" alt="tizenot mondat" />
					</BookImage>
					<BookHeaderDescription>
						<h2>{mybook.title}</h2>
						<h3>{mybook.description}</h3>
						<h3> A Vízió, Érzés, Technika az Egyensúly, Elengedettség, Egyenesség című
							könyv és a Tizenöt mondat című könyv folytatása</h3>
						<BookPrice>{mybook.price} Ft</BookPrice>
						<AddToCartButton key={mybook.id} book={mybook}></AddToCartButton>
					</BookHeaderDescription>


				</BookHeader>

				<p>


				</p>
				<p>

				</p>

				<div className="container">
					<div className="box">
						<div className="box_image">
							<img src="/img/konyvek/VET_1_thumbnail.png" onClick={() => setIndex(0)} alt={mybook.title} />
						</div>
						<div className="box_description">
							<p>
								Az ország egyik elismert lókiképzője, dr. Boór-Gőblyös István, harmadik könyvében könnyen megérthető formában foglalja össze a
								lókiképzés folyamatát.
							</p>
						</div>
					</div>
					<div className="box">
						<div className="box_image">
							<img src="/img/konyvek/VET_2_thumbnail.webp" onClick={() => setIndex(1)} alt={mybook.title} />
						</div>
						<div className="box_description">
							<p>
								A Vízió, Érzés, Technika az Egyensúly, Elengedettség, Egyenesség című
								könyv és a Tizenöt mondat című könyv folytatása.
							</p>
						</div>
					</div>
					<div className="box">
						<div className="box_image">
							<img src="/img/konyvek/VET_3_thumbnail.webp" onClick={() => setIndex(2)} alt={mybook.title} />
						</div>
						<div className="box_description">
							<p>
								Míg az előbbi a lókiképzés alapfogalmait foglalja össze, hogy az olvasóknak helyes elképzelésük alakuljon ki a lóval való foglalkozás egyes kérdéseiről, a második
								kötet pedig a kiképzés alapszemléletét írja le, addig jelen, harmadik
								kötet magát a lókiképzés folyamatát, az egyes lépéseket taglalja.
							</p>
						</div>
					</div>
					<div className="box">
						<div className="box_image">
							<img src="/img/konyvek/VET_4_thumbnail.webp" onClick={() => setIndex(3)} alt={mybook.title} />
						</div>
						<div className="box_description">
							<p>
								A kiképzés folyamata igen összetett, hiszen az alapelvek ugyan fekete-fehérek, 
								azok könnyen megfogalmazhatóak, de a pedagógia, a képzés folyamata nem az.
								Lovasként, lókiképzőként azonban mégiscsak szükség van egyfajta vezérfonalra,
								ami általános útmutatóként szolgál és ami minden egyes ló
								képzése során alkalmazható.
							</p>
						</div>
					</div>
					<div className="box">
						<div className="box_image">
							<img src="/img/konyvek/VET_5_thumbnail.png" onClick={() => setIndex(4)} alt={mybook.title} />
						</div>
						<div className="box_description">
							<p>
								
								A könyv mindebbe látványos képekkel vezeti be az olvasót, forgatása
								– az első és második kötethez hasonlóan - nem csak a lovasoknak, de
								szüleiknek, barátaiknak, családtagjaiknak is informatív olvasmány.
								Egyszerű szövegezése, tőszövegének rövidsége, a tőszöveg részletes
								magyarázatai akár a lókiképzésben szerény gyakorlattal rendelkező
								olvasó számára is lehetővé teszi a képzés logikájának megértését.
							</p>
						</div>
					</div>
				</div>
			</BookDescription>
			<Lightbox
				slides={photos}
				open={index >= 0}
				index={index}
				close={() => setIndex(-1)}
				// enable optional lightbox plugins
				plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
			/>

		</div>
	)
}

export default FifteenProposition;