import { useState } from "react";
import { useContext } from "react";


import Lightbox from "yet-another-react-lightbox";
// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import { BooksContext } from "../../contexts/books.context";
import AddToCartButton from "../../components/add-to-cart-button/add-to-cart-button.componenet";


import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";


import { BookDescription, BookHeader, BookImage, BookHeaderDescription, BookPrice} from "./egyensuly.styles";

const photos = [
	{ src: "/img/konyvek/Dr_Goblyos_Istvan_lovaskonyve_mintaoldal_01.png", width: 1200, height: 600 },
	{ src: "/img/konyvek/Dr_Goblyos_Istvan_lovaskonyve_mintaoldal_02.png", width: 1200, height: 600 },
	{ src: "/img/konyvek/Dr_Goblyos_Istvan_lovaskonyve_mintaoldal_03.png", width: 1200, height: 600 },
	{ src: "/img/konyvek/Dr_Goblyos_Istvan_lovaskonyve_mintaoldal_04.png", width: 1200, height: 600 },
	{ src: "/img/konyvek/Dr_Goblyos_Istvan_lovaskonyve_mintaoldal_05.png", width: 1200, height: 600 },
	{ src: "/img/konyvek/Dr_Goblyos_Istvan_lovaskonyve_mintaoldal_06.png", width: 1200, height: 600 },
];



const Egyensuly = () => {
	const { getBook } = useContext(BooksContext);
	const [index, setIndex] = useState(-1);

	const mybook = getBook(3);

	return (
		<div>
			<BookDescription className="content_inner">
				<BookHeader>
					<BookImage>
						<img src="/img/konyvek/Dr_Goblyos_Istvan_lovaskonyve_kezben.webp" alt="EGYENSÚLY, ELENGEDETTSÉG, EGYENESSÉG" />
					</BookImage>
					<BookHeaderDescription>
						<h2>EGYENSÚLY, ELENGEDETTSÉG, EGYENESSÉG</h2>
						<h3>Alapfogalmak és technikák a helyes lókiképzéshez</h3>
						<BookPrice>{mybook.price} Ft</BookPrice>
						<AddToCartButton key={mybook.id} book={mybook}></AddToCartButton>
					</BookHeaderDescription>
				</BookHeader>
				<div className="container">
					<div className="box">
						<div className="box_image">
							<img src="/img/konyvek/Dr_Goblyos_Istvan_lovaskonyve_mintaoldal_01_thumbnail.webp" onClick={() => setIndex(0)} alt="EGYENSÚLY, ELENGEDETTSÉG, EGYENESSÉG" />
						</div>

						<div className="box_description">
							<p>
								Az ország egyik elismert lókiképzője, Dr.Gőblyös István könnyen megérthető formában foglalja össze a
								lókiképzés legfontosabb elméleti és gyakorlati alapfogalmait.
							</p>
						</div>
					</div>
					<div className="box">
						<div className="box_image">
							<img src="/img/konyvek/Dr_Goblyos_Istvan_lovaskonyve_mintaoldal_02_thumbnail.webp" onClick={() => setIndex(1)} alt="EGYENSÚLY, ELENGEDETTSÉG, EGYENESSÉG" />
						</div>
						<div className="box_description">
							<p>
								A szerző első könyvében az elveszett magyar lovaskultúra nyomába ered és az akadémista stílusú
								lókiképzés elfeledett hagyományait eleveníti fel, melyet a boldogság kultúrájának neve.
								Vallja, hogy
								ennek felfedezésével bármely lovas megélheti a boldogságot, lova és önmaga kiteljesedését.</p>
						</div>
					</div>
					<div className="box">
						<div className="box_image">
							<img src="/img/konyvek/Dr_Goblyos_Istvan_lovaskonyve_mintaoldal_03_thumbnail.webp" onClick={() => setIndex(2)} alt="EGYENSÚLY, ELENGEDETTSÉG, EGYENESSÉG" />
						</div>
						<div className="box_description">
							<p>
							A látványos képekkel illusztrált fejezetek segítséget nyújtanak az olvasónak, hogy az egyes
							kérdéskörökről helyes elképzelése alakuljon ki, és önmagát fejlesztve eljusson azokhoz az érzésekhez,
							amelyek a lókiképzéshez elengedhetetlenek.
							</p>
						</div>
					</div>
					<div className="box">
						<div className="box_image">
							<img src="/img/konyvek/Dr_Goblyos_Istvan_lovaskonyve_mintaoldal_04_thumbnail.webp" onClick={() => setIndex(3)} alt="EGYENSÚLY, ELENGEDETTSÉG, EGYENESSÉG" />
						</div>

						<div className="box_description">
							<p>
								A könyv kezdő és haladó lovasok számára is hasznos
								segédlet a mindennapi lókiképzéshez: bárhol is ütik fel, biztosan találnak olyan gondolatot és tanácsot,
								amely segíti őket a fejlődésben.
							</p>
						</div>
					</div>
					<div className="box">
						<div className="box_image">
							<img src="/img/konyvek/Dr_Goblyos_Istvan_lovaskonyve_mintaoldal_05_thumbnail.webp" onClick={() => setIndex(4)} alt="EGYENSÚLY, ELENGEDETTSÉG, EGYENESSÉG" />
						</div>
						<div className="box_description">
							<p>
								A szerző szerint a „lovasérzés” ugyanis tanulható, amit bárki átélhet a ló figyelmes tanulmányozásával,
								gondolkodással, kitartással és azzal az elméleti és gyakorlati tudással, amelyet most ez a könyv ad közzé.
							</p>							
						</div>
					</div>
					<div className="box">
						<div className="box_image">
							<img src="/img/konyvek/Dr_Goblyos_Istvan_lovaskonyve_mintaoldal_06_thumbnail.webp" onClick={() => setIndex(5)} alt="EGYENSÚLY, ELENGEDETTSÉG, EGYENESSÉG" />
						</div>

						<div className="box_description">
							<p>							
								Ugyanakkor a könyv forgatása nemcsak lovasoknak, hanem szüleiknek, barátaiknak, családtagjaiknak is
								ajánlott, hogy jobban megértsék lovas barátaik, családtagjaik szenvedélyét, vagy csupán
								gyönyörködjenek a kiadvány páratlan lovas fotóiban.
							</p>							
						</div>
					</div>
				</div>
			</BookDescription>
			<Lightbox
				slides={photos}
				open={index >= 0}
				index={index}
				close={() => setIndex(-1)}
				// enable optional lightbox plugins
				plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
			/>
		</div>
	)

}

export default Egyensuly;