import { useState, useContext } from 'react';
import { useStripe, useElements, 
	CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';


import { CartContext } from '../../contexts/cart.context';
import {PaymentFormContainer, FormContainer, CardContainer, CardTemplate, PaymentButton,
ShippingAddressContaioner,
FormRow} from './payment-form.styles.jsx'

const PaymentForm = () => {

	const stripe = useStripe();
	const elements = useElements();
	const [isProcessingPayment, setIsProcessingPayment] = useState(false);
	const [isBillRequired, setIsBillRequired] = useState(false);
	const { cartTotalValue, emptyItems, cartItemsCount,cartShortContent} = useContext(CartContext);
	const { register, handleSubmit,formState: { errors } } = useForm();

	const navigate = useNavigate();
	

	const handleBillRequired = (e) => {
		setIsBillRequired(e.target.checked);
	}
	const paymentHandler = async (e) => {

		console.log(e);
		//e.preventDefault();
		if (!stripe || !elements) {
			return;
		}
		setIsProcessingPayment(true);
		const response = await fetch('/.netlify/functions/create-payment-intent', {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				amount: (cartTotalValue+ 2510) * 100 ,
				shipping: {
					name: e.shipping_name,
					phone: e.shipping_phone,
					address: {
						city: e.shipping_city,
						country: 'HU',
						line1: e.shipping_line1,
						line2: e.shipping_line2,
						postal_code: e.shipping_postal_code,
					}
				},
				description: cartShortContent,
				metadata:{
					konyvek: cartShortContent,
					szamlat_ker: e.billing_required,
					adoszam: e.billing_vat_id,
				},
				}),
		}).then((res) => {
			return res.json();
		});

		const clientSecret = response.paymentIntent.client_secret;

		
		
		const paymentResult = await stripe.confirmCardPayment(clientSecret, {
			payment_method: {
				card: elements.getElement(CardNumberElement),
				billing_details:{
					name: e.billing_name,
					address:{
						city: e.billing_city,
						country: 'HU',
						line1: e.billing_line1,
						line2: e.billing_line2,
						postal_code: e.billing_postal_code,
					},
				},
				// metadata: {
				// 	konyvek: cartShortContent,
				// }
			},
			shipping:{
				name: e.shipping_name,
				phone: e.shipping_phone,
				address:{
					city: e.shipping_city,
					country: 'HU',
					line1: e.shipping_line1,
					line2: e.shipping_line2,
					postal_code: e.shipping_postal_code,
				}
			}
			// description: cartShortContent,
			
		});

		setIsProcessingPayment(false);

		if (paymentResult.error) {
			alert(paymentResult.error.message);
		} else {
			if (paymentResult.paymentIntent.status === 'succeeded') {
				emptyItems();
				alert('Köszönjük a vásárlást!');
				navigate("/");
			}
		}
	};

	const cardElementOptions = {
		style: {
			base: {
				color: "#2d2d2d",
				fontSize: "24px",
				fontFamily:"'Courier'",
			},
			focus: {
				color: "#2d2d2d",
				fontSize: "24px",
				fontFamily: "'Courier'",
				fontWeight: "600",
			},
			invalid: {
				color: "#fa755b",
				fontSize: "24px",
				fontFamily: "'Courier'",

			}
		},
	}

	const cardNumberElementOptions = {
		style: {
			base: {
				color: "#2d2d2d",
				fontSize: "24px",
				fontFamily: "'Courier'",
			},
			focus: {
				color: "#2d2d2d",
				fontSize: "24px",
				fontFamily: "'Courier'",
				fontWeight: "600",
			},
			invalid: {
				color: "#fa755b",
				fontSize: "24px",
				fontFamily: "'Courier'",

			}
		},
		showIcon: true,
		iconStyle: 'solid',
	}


	if (cartItemsCount > 0)
	return (
		<PaymentFormContainer>
			<FormContainer onSubmit={handleSubmit(paymentHandler)} >
				<ShippingAddressContaioner>
					<h2>Szállítási adatok</h2>
					<FormRow className='form-group'>
						<label>Teljes név</label>
						<input type="text" {...register("shipping_name", { required: true })} placeholder="Utó és vezetéknév" />
						{errors.shipping_name && <p>A nevet kötlező megadni</p>}
					</FormRow>
					<FormRow>
						<label>Város</label>
						<input type="text" {...register("shipping_city", { required: true })} />
						{errors.shipping_city && <p> A várost kötlező megadni</p>}
					</FormRow>
					<FormRow className='form-group'>
						<label>1. címsor</label>
						<input type="text" {...register("shipping_line1", { required: true })} placeholder="Utca, házszám"  />
						{errors.shipping_line1 && <p> A címsort kötlező megadni</p>}
					</FormRow>
					<FormRow className='form-group'>
						<label>2. címsor</label>
						<input type="text" {...register("shipping_line2", { required: false })} placeholder="Lakás, lakosztály, lakóegység száma" />
						{errors.shipping_line2 && <p> A címsort kötlező megadni</p>}
					</FormRow>
					<FormRow className='form-group postal_code'>
						<label>Irányítószám</label>
						<input type="text" {...register("shipping_postal_code", { required: true })} />
						{errors.shipping_postal_code && <p> A Irányítószámot kötlező megadni</p>}
					</FormRow>
					<FormRow className='form-group phone'>
						<label>Telefonszám</label>
						<input type="text" {...register("shipping_phone", 
						{ required: true, pattern: /^\(?([0-9]{2})\)?[-. ]?([0-9]{2})[-. ]?([0-9]{3})\)?[-. ]?([0-9]{4})$/ })} 
							placeholder="06 20 123 4567"/>
						{errors.shipping_phone && <p> A Telefonszámot kötlező megadni</p>}
					</FormRow>
					<FormRow className='form-group checkbox'>
						<label>Számlát kérek</label>
						<input type="checkbox" {...register("billing_required",
							{ required: false })}
							onClick={handleBillRequired}
							placeholder="06 20 123 4567" />
						{errors.shipping_phone && <p> A Telefonszámot kötlező megadni</p>}
					</FormRow>

				</ShippingAddressContaioner>
				{
					isBillRequired && 
					<ShippingAddressContaioner>
						<h2>Számlázási adatok</h2>
						<FormRow className='form-group'>
							<label>Vásárló neve</label>
							<input type="text" {...register("billing_name", { required: true })} placeholder="Cég vagy személy név" />
							{errors.billing_name && <p>A nevet kötlező megadni</p>}
						</FormRow>
						<FormRow>
							<label>Város</label>
							<input type="text" {...register("billing_city", { required: true })} />
							{errors.billing_city && <p> A várost kötlező megadni</p>}
						</FormRow>
						<FormRow className='form-group'>
							<label>1. címsor</label>
							<input type="text" {...register("billing_line1", { required: true })} placeholder="Utca, házszám" />
							{errors.billing_line1 && <p> A címsort kötlező megadni</p>}
						</FormRow>
						<FormRow className='form-group'>
							<label>2. címsor</label>
							<input type="text" {...register("billing_line2", { required: false })} placeholder="Lakás, lakosztály, lakóegység száma" />
						</FormRow>
						<FormRow className='form-group postal_code'>
							<label>Irányítószám</label>
							<input type="text" {...register("billing_postal_code", { required: true })} />
							{errors.billing_postal_code && <p> A Irányítószámot kötlező megadni</p>}
						</FormRow>
						<FormRow className='form-group phone'>
							<label>Vásárló adószáma</label>
							<input type="text" {...register("billing_vat_id", { required: false })} placeholder="Adószám" />
						</FormRow>
					</ShippingAddressContaioner>
				}
				
				

				<CardContainer>
					<h2>Kártya adatok</h2>
					<CardTemplate>
						<img src="/credit_card_chip.png" alt="" id="card_chip" />
						<CardNumberElement id="card_number" options={cardNumberElementOptions} />
						<CardExpiryElement id="card_expiry" options={cardElementOptions} />
						<CardCvcElement id="card_cvc" options={cardElementOptions} />

					</CardTemplate>

					<Link to='/altalanos-szerzodesi-feltetelek'>A megrendelés előtt kérjük, kattintson ide, és olvassa el a fizetéssel és kiszállítással kapcsolatos tájékoztatót tartalmazó Általános Szerződési Feltételeket!</Link>

					<PaymentButton disabled={isProcessingPayment} isLoading={isProcessingPayment} >Fizetés<img src="/chevron-double-right.png" alt="Fizetés" /></PaymentButton>
				</CardContainer>

			</FormContainer>
		</PaymentFormContainer>
		
	)
}

export default PaymentForm;