import { createContext, useState} from "react";

import BOOKS from '../books-data.json';

export const BooksContext = createContext({
	books: [],
});

const getBookById = (books,id) => {
	const book_by_id = books.find(
		(book) => book.id === id
	);
	return book_by_id;
}


export const BooksProvider = ({children}) => {
	const [books] = useState(BOOKS);
	const getBook = (id) => {
		return getBookById(books,id);
	}

	const value = { books, getBook };
	return (
		<BooksContext.Provider value={value}>
		 {children} 
		</BooksContext.Provider>
	)
}