import { loadStripe } from "@stripe/stripe-js";

export const stripePromise = loadStripe(
	process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
	{
		locale: 'hu',
	}
	
);

export const stripeOptions = {

	appearance: {
		theme: 'stripe',
		variables: {
			colorPrimary: '#2d2d2d',
			colorBackground: '#ffffff',
			colorText: '#2d2d2d',
			colorDanger: '#df1b41',
			fontFamily: 'Open Sans',
			spacingUnit: '2px',
			borderRadius: '0px',
			fontSizeBase: '20px',
			fontWeight: '600',
			// See all possible variables below
		}
	},
};