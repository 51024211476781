import styled from 'styled-components';


export const BookDescription = styled.div`
  
	text-align: left;
	img{
		max-width: 100%;
	}
`;

export const Gallery = styled.div`
	display: grid;
	width: 100%;
	grid-template-columns: repeat(1, 1fr);
	@media (min-width: 768px) {
		grid-template-columns: repeat(2, 1fr);
		img{
			max-width: 100%;
		}
	}
	@media (min-width: 1200px) {
		grid-template-columns: repeat(3, 1fr);
	}
	
	column-gap: 20px;
	row-gap: 20px;
	img{
		width: auto;
		margin: auto;
		max-width: 100%;
	}
`;

export const BookHeader = styled.div`
	margin-top: 100px;
	display: flex;
	border-bottom: 1px solid;
	padding-bottom: 80px;
	margin-bottom: 80px;
	@media (max-width: 1100px) {
		display: block;
	}

`

export const BookImage = styled.div`
	width: 50%;
	@media (max-width: 1100px) {
		width: 100%;
	}
`

export const BookHeaderDescription = styled.div`

	width: 50%;
	h2{

		font-family: 'Manuale';
		font-style: normal;
		font-weight: 700;
		font-size: 56px;
		line-height: 100%;
		max-width: 230px;

	}
	@media (max-width: 1200px) {
		h2{
			font-size: 40px;

		}
	}
	@media (max-width: 1100px) {
		width: 100%;
		text-align: center;
		h2{
			max-width: 100%;
		}
		button{
			margin: 0 auto;
		}
	}
`

export const BookPrice = styled.div`
margin-top: 30px;
margin-bottom: 30px;
font-family: 'Manuale';
font-style: normal;
font-weight: 700;
font-size: 56px;
line-height: 100%;

color: #2D2D2D;
@media (max-width: 1200px) {
		
			font-size: 40px;

		
	}

`