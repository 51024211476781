import { BreakStyled, BreakTitle, BreakInside } from "./break.styles";
import { FooterIcons,FooterIcon } from "../footer/footer.styles";

const Break = () => {
	return (
		<BreakStyled>
			<BreakInside className="content_inner">
				<BreakTitle>Kövess minket, hogy időben értesűlj<br/>
					 a legfrisebb eseményekről.<br/>
					<FooterIcons>
						<FooterIcon to='https://youtube.com/playlist?list=PLljB2Qc8pBwuQb26J20REELosI9jMXsjW&si=SwliRExy2-h4LTmY' target="_blank">
							<img src="youtube_goblyos.png" alt="Facebook BoorGoblyos Istvan" />
						</FooterIcon>
						<FooterIcon to='https://zablaeskengyel.blog.hu/' target='_blank'>
							<img src="/Blog.hu.png" alt="Zabla es kengyel blog" />
						</FooterIcon>
						<FooterIcon to='https://www.facebook.com/istvan.goblyos' target="_blank">
							<img src="facebook.png" alt="Facebook BoorGoblyos Istvan" />
						</FooterIcon>
					</FooterIcons>
				</BreakTitle>
			</BreakInside>
		</BreakStyled>
	)
}

export default Break;