import { useContext } from 'react';


import { CartContext } from '../../contexts/cart.context';

import {BookCardContainer, Header, Footer, Title} from './book-card.styles';
import { Link } from 'react-router-dom';
import  Button,  {BUTTON_TYPE_CLASSES } from '../button/button.component';

const BookCard = ({book}) => {
	const {title, route, imageUrl, description } = book;
	const {addItemToCart} = useContext(CartContext);
	const addBookToCart = () => addItemToCart(book);


	return (
		<BookCardContainer>
			<Header>
				<Link to={route}>
					<img src={process.env.PUBLIC_URL + '/img/' + imageUrl} alt={`${title}`} />
				</Link>
				<Button buttonType={BUTTON_TYPE_CLASSES.inverted} onClick={addBookToCart} className="add_to_cart" ><img src='/bag-plus.png' alt='Megvásárolom' />Megvásárolom</Button>
			</Header>
			
			<Footer>
				<Title>{title}</Title>
				<p>{description}</p>
				<Link to={route}><Button buttonType={BUTTON_TYPE_CLASSES.base}>Tovább olvasom<img src='/Arrow.png' alt='Tovább olvasom' /></Button></Link>
			</Footer>
		</BookCardContainer>
	)
	
}

export default BookCard;