import styled from "styled-components";

export const AuthorStyled = styled.div`


	h2{

		font-family: 'Playfair Display SC';
		font-style: normal;
		font-weight: 700;
		font-size: 48px;
		line-height: 75px;
		/* Dr. Gobjos Istvan */
	}

	h3{
		font-family: 'Playfair Display SC';
		font-style: italic;
		font-weight: 400;
		font-size: 24px;
		line-height: 32px;
		display: flex;
	}
	p{
		margin-bottom: 1.5em;
	}
	@media (max-width: 767.9px) {
		h2{
			font-size: 36px;
			line-height: 50px;
		}
	}
	@media (max-width: 500px) {
		h2{
			font-size: 28px;
			line-height: 45px;
		}
	}
	
	/* border-bottom: 1px solid; */

`

export const AuthorContainer = styled.div`
	margin-top: 100px;
	display: block;
	@media (max-width: 600px) {
		margin-top: 50px;
	}
	
`

export const AuthorImage = styled.div`
	float: right;
	width: 40%;
	margin-left: 20px;
	img{
		width: 100%;
		height: auto;
	}
	@media (max-width: 600px) {
		float: none;
		width: 100%;
		margin-left: 0px;
	}
`

