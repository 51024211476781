import{FooterContainer, FooterInner, FooterText, FooterIcons, FooterIcon} from './footer.styles';

const Footer = () =>{
	return (
		<FooterContainer>
				<FooterInner className='content_inner'>
					<FooterText>
					Copyright © 2023 - Dr. Boór-Gőblyös István - Minden jog fenntartva.
					</FooterText>
							<FooterIcons>
								<FooterIcon to='https://youtube.com/playlist?list=PLljB2Qc8pBwuQb26J20REELosI9jMXsjW&si=SwliRExy2-h4LTmY' target="_blank">
									<img src="youtube_goblyos.png" alt="Facebook BoorGoblyos Istvan" />
								</FooterIcon>
					<FooterIcon to='https://zablaeskengyel.blog.hu/' target='_blank'>
						<img src="/Blog.hu.png" alt="Zabla es kengyel blog" />
					</FooterIcon>
								<FooterIcon to='https://www.facebook.com/istvan.goblyos' target="_blank">
									<img src="facebook.png" alt="Facebook BoorGoblyos Istvan" />
								</FooterIcon>
							</FooterIcons>
				</FooterInner>
		</FooterContainer>
	
	)
}

export default Footer;