import styled from "styled-components";

export const LovardaFrontStyled = styled.div`
	
	width: 100%;
	overflow: hidden;
	color : #2D2D2D;
	 h2{
		border-top: 1px solid #2d2d2d;
		padding-top: 80px;
		font-family: 'Manuale';
		font-style: normal;
		font-weight: 700;
		font-size: 56px;
		line-height: 100%;

	 }

	 button{
		img{
			margin-top: 16px;
			margin-left: 5px;
			width: 29px;
			height: 16px;
		}
	 }
	 
	 `;

	 export const FirstRow = styled.div`
	 	margin-top: 50px;
	 	margin-left: calc((100% - 1600px)/2);
		@media (max-width: 1600px) {
			margin-left: 50px;
		}
		@media (max-width: 600px) {
			margin-left: 20px;
		}
	 `;

	 export const SecondRow = styled.div`
	 	margin-top: 25px;
		margin-right: calc((100% - 1600px)/2);
		@media (max-width: 1600px) {
			margin-right: 50px;
		}
		@media (max-width: 600px) {
			margin-right: 20px;
		}
	 `;




