import styled from "styled-components";

export const BookCarouselStyled = styled.div`
margin-top: 100px;

.carousel-control-prev {
	right: 35px;
	left: auto;
}

.carousel-control-prev,
.carousel-control-next{
	width: 30px;

}

.carousel-control-prev-icon,
.carousel-control-next-icon{
	position: relative;
	top: -25%;
}
@media (max-width: 1100px) {
	margin-bottom: 30px;
}
`


export const BookCarouselItemStyled = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	@media (max-width: 900px) {
		display: block;
	}

`

export const BookCarouselImage = styled.div`
	width: 50%;
	img{
		width: 100%;
	}
	@media (max-width: 900px) {
		width: 80%;
		padding-bottom: 30px;;
	}
`

export const BookCarouselDescription = styled.div`
	width: 50%;
	h3{
		margin-top: 30px;
		font-family: 'Manuale';
		font-style: normal;
		font-weight: 700;
		font-size: 56px;
		line-height: 100%;
		color: #2D2D2D;
		margin-bottom: 40px;
		max-width: 230px;
	}
	button{
		margin-top: 30px;
		line-height: 70px;
		height: 70px;
		img{
			margin-top: 15px;
			width: 37px;
			height: 37px;
			margin-right: 15px;
			

		}
		&:hover 
		{
			img{
				-webkit-filter: invert(100%) !important; 
			}
    		
		}
	}

	@media (max-width: 900px) {
		width: 80%;
		h3{
			max-width: 100%;
		}
		button{
			margin-left: auto;
			margin-right: auto;
		}
	}

	@media (max-width: 600px) {
		width: 100%;
		h3{
			max-width: 100%;
			font-size: 40px;
		}
		button{
			margin-left: auto;
			margin-right: auto;
		}
	}
`



