import styled from "styled-components";

export const BreakStyled = styled.div`
	background: url('/img/zita_elvalaszto.webp');
	 background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
	margin-top: 100px;
	width: 100%;
	height: 393px;
	@media (max-width: 600px) {
		height: 500px;
		background: url('/img/break_background.png');
	}
`

export const BreakTitle = styled.div`
	position: relative;
	left: 50%;
	padding-top: 100px;
	font-family: 'Manuale';
	font-style: italic;
	font-weight: 400;
	font-size: 45px;
	line-height: 56px;
	/* or 114% */

	text-align: right;
	color: #FFFFFF;
	width: 50%;
	text-align: right;

	
	@media (max-width: 1000px) {
		font-size: 28px;
		line-height: 40px;
	}

	@media (max-width: 600px) {
		width: 60%;
		left: 40%;
		font-size: 32px;
		line-height: 36px;
		padding-top:20px;

	}

`;

export const BreakInside = styled.div`


`