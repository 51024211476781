
import { Link } from "react-router-dom";
import InvertedButton from '../../components/button/button.component';

import { ASZF } from "./altalanos-feltetelek.styles";

const AlatanosFeltetelek = () =>{
	return (
		<ASZF className="content_inner">
			<h1>Általános Szerződési Feltételek (ÁSZF)</h1>
			<p>
				Kérjük, hogy a megrendelőlap elküldése előtt figyelmesen olvassa el az Általános Szerződési Feltételeket és kizárólag abban az esetben küldje el rendelését, ha annak minden pontjával egyetért és kötelező érvényűnek tekinti magára nézve. Jelen dokumentum nem kerül iktatásra, kizárólag elektronikus formában kerül megkötésre.
			</p>

			<h2>ÜZEMELTETŐI ADATOK</h2>
			<p>
				Üzemeltető: Hidevölgy Kft.<br/>
				Üzemeltető címe: 2472 Kajászó, Szentpéterpuszta 0172/5. hrsz.<br />
				Üzemeltető adószáma: 32176108-1-07<br />
				Cégjegyzékszám: 07 09 034325<br />
				Üzemeltető bankszámla száma: 10403174-50527076-77861014<br />
				Kapcsolattartó: Dr. Boór-Gőblyös István<br />
				Kapcsolattartó elérhetősége: 
					<Link to="&#109;&#97;&#105;&#108;&#116;&#111;&#58;%67%6F%62%6C%79%6F%73%2E%6B%6F%6E%79%76%72%65%6E%64%65%6C%65%73%40%67%6D%61%69%6C%2E%63%6F%6D">
						 &nbsp;&#103;&#111;&#98;&#108;&#121;&#111;&#115;&#46;&#107;&#111;&#110;&#121;&#118;&#114;&#101;&#110;&#100;&#101;&#108;&#101;&#115;&#64;&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;
						</Link><br />

			</p>
			
			<h2>ÁLTALÁNOS TUDNIVALÓK</h2>
			<p>
				A goblyos.com weboldalon megjelenített termék (könyv) online rendelhető és postai kézbesítéssel, a megrendelő által megadott címen vehető át.
				 A megrendelőlapon feltüntetett bruttó 9.950- forintos ár a szállítási díjat nem tartalmazza.
				  A szállítással és fizetéssel kapcsolatos tudnivalókról lejjebb talál tájékoztatást.
			</p>
			

			<h2>KISZÁLLÍTÁS</h2>
			
			A megrendelt terméket (könyvet) a Magyar Posta Zrt. igénybevételével juttatjuk el Önhöz. 
			A szállítás díja a megrendelőt terheli, amely belföldön bruttó 2.510 Ft.
			Egyéb országokba történő szállításról kérjük, érdeklődjön a <Link to="&#109;&#97;&#105;&#108;&#116;&#111;&#58;%67%6F%62%6C%79%6F%73%2E%6B%6F%6E%79%76%72%65%6E%64%65%6C%65%73%40%67%6D%61%69%6C%2E%63%6F%6D">
				&nbsp;&#103;&#111;&#98;&#108;&#121;&#111;&#115;&#46;&#107;&#111;&#110;&#121;&#118;&#114;&#101;&#110;&#100;&#101;&#108;&#101;&#115;&#64;&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;
			</Link> e-mail címen! 
			 A szállítási díjat kérjük, a megrendelt termék (könyv) árával együtt megfizetni. 
			 A könyv várható kézbesítése: a fizetés beérkezésétől számított kb. 1 héten belül.

			<h2>ELÁLLÁSI JOG</h2>
			
			A távollevők között kötött szerződésekről szóló, 17/1999 (II.5) kormányrendelet szabályozása értelmében a fogyasztó a 
			megrendelt termék kézhez vételétől számított 8 munkanapon belül indoklás nélkül elállhat a szerződéstől, 
			visszaküldheti a megrendelt terméket. Jelen tájékoztató hiányában jogosult a fogyasztó három hónap elteltéig 
			gyakorolni az elállási jogát. Az elállási jog gyakorlása esetén a vásárlót a termék visszajuttatásának 
			költségén kívül más költség nem terheli. Amennyiben élni szeretne az elállás jogával, úgy azt a fent megadott
			 elérhetőségek közül bármelyiken közölheti.

			<h2>ADATKEZELÉS</h2>
			
			A weboldal használata során a goblyos.com részére rendelkezésére bocsátott személyes adatokat az üzemeltető bizalmasan
			 kezeli és nem adja ki további fél számára, kivételt jelent ez alól a futárszolgálat, 
			 amely a megrendelt terméket kiszállítja. A jelen általános szerződési feltételekben nem szabályozott
			  kérdésekben a polgári törvénykönyv, a távollévők között kötött szerződésekről szóló 17/1999 (II.5.)
				 kormányrendelet és az elektronikus kereskedelmi szolgáltatások, valamint az információs társadalommal
				  összefüggő szolgáltatások egyes kérdéseiről szóló 2001. évi CVIII. tv. rendelkezései az irányadók. A
					 goblyos.com weboldal böngészésével, továbbá megrendelésének elküldésével elfogadja az
					  üzemeltető Általános Szerződési Feltételeit, továbbá adatkezelési elveit.

			<Link to="/checkout" ><InvertedButton>Vissza a Rendeléshez</InvertedButton></Link>
	</ASZF>
	)
};

export default AlatanosFeltetelek;