import {CartItemConatiner, Title, ItemDetails, Quantity, Price} from './cart-item.styles';

const CartItem = ({cartItem}) => {
	const { title, quantity, price, imageUrl } = cartItem;

	return (
		<CartItemConatiner>
			<img src={process.env.PUBLIC_URL + '/img/' + imageUrl} alt={`${title}`} />
			<ItemDetails>
				<Title>{title}</Title>
				<Quantity>{quantity} db</Quantity>
				<Price>{quantity * price} Ft</Price>
			</ItemDetails>
		
		</CartItemConatiner>
	)

}

export default CartItem;