
import styled from "styled-components";


import Button from '../button/button.component';

export const PaymentFormContainer = styled.div`
	margin-top: 60px;
	button{
		margin-top: 20px;
		height: 60px;
		letter-spacing: 0.5px;
		line-height: 60px;
		font-family: 'Manuale';
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		color: #FFFFFF;
		img{
			width: 36px;
			height: 36px;
			margin-top: 13px;
		}
		&:hover {
			img{
				-webkit-filter: invert(100%) !important;
			}
		}
	}

		h2{
		font-family: 'Manuale';
		font-style: normal;
		font-weight: 700;
		font-size: 36px;
		line-height: 100%;
		color: #2D2D2D;
		margin-bottom: 30px;
	}

`;

export const FormContainer = styled.form`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	@media (max-width: 1000px) {
		display: block;
	}
`; 

export const AddressContainer = styled.div`
	flex: 1;
	padding-right: 30px;
	border-right: 1px solid #2d2d2d;
	max-width: 500px;
	@media (max-width: 1000px) {
		display: block;
		padding-right: 0px;
		border-right: none;
		max-width: 100%;
	}
`

export const FormRow = styled.div`
	margin-top: 10px;
	label{
		display: block;
				

		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 100%;
		/* identical to box height, or 20px */

		color: #2D2D2D;


	}
	input{
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 16px 16px 16px 16px;
		width: 100%;
		border: 1px solid #2D2D2D;
		border-radius: 4px;
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 150% */
		font-feature-settings: 'liga' off;0
	}
	&.postal_code{
		width: 30%;
		display: inline-block;
	}
	&.phone{
		width: 70%;
		display: inline-block;
		padding-left: 30px;
	}

	&.checkbox{
		input{
			width: 30px;
			height: 30px;

		}
	}
`

export const ShippingAddressContaioner = styled.div`
	flex: 1;
	padding-right: 30px;
	border-right: 1px solid #2d2d2d;
	max-width: 500px;
	@media (max-width: 1000px) {
		display: block;
		padding-right: 0px;
		border-right: none;
		max-width: 100%;
	}
	&:nth-child(2){
		padding-left: 30px;
	}
	p{
		color: red;
		font-size: 16px;
	}
	
`

export const CardContainer = styled.div`
 	padding-left: 30px;
	flex: 2;
	max-width: 500px;
	
	a{
		font-family: 'Open Sans';
		font-weight: 400;
		font-size: 18px;
		line-height: 20px;
		text-decoration: underline;
		color: #000;
	}

	@media (max-width: 1000px) {
		margin-top: 30px;
		display: block;
		padding-left: 0px;
		border-right: none;
		max-width: 100%;
	}
`;

export const CardTemplate = styled.div`
	border: 1px solid #2d2d2d;
	border-radius: 15px;
	width: 342px;
	height: 216px;
	#card_chip{
		position: relative;
		width: 50px;
		height: 50px;
		top: 80px;
		left: 30px;
	}
	#card_number{
		position: relative;
		top: 85px;
		left: 10px;
	}
	#card_expiry{
		position: relative;
		top: 100px;
		left: 10px;
	}
	#card_cvc{
		position: relative;
		top: 70px;
		left: 230px;
	}
`



export const PaymentButton = styled(Button)`
  margin-left: auto;
  margin-top: 30px;
`;





//<CardElement id="card_number" options={cardElementOptions} />