
import { useState } from "react"; 
import { Link } from 'react-router-dom';


import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import InvertedButton from '../../components/button/button.component';

import { LovardaFrontStyled, FirstRow, SecondRow } from "./lovarda-front.styles";


const photos1 = [
	{ src: "/img/lovarda/szentpeterpuszta06-11-15_7350_f-neubauer.webp", width: 2032, height: 1352 },
	{ src: "/img/lovarda/szentpeterpuszta06-11-15_7611_f-neubauer.webp", width: 2032, height: 1352 },
	{ src: "/img/lovarda/szentpeterpuszta06-11-15_7731_f-neubauer.webp", width: 2032, height: 1352 },
];
const photos2 = [
	{ src: "/img/lovarda/szentpeterpuszta06-11-15_7652_f-neubauer.webp", width: 2032, height: 1352 },
	{ src: "/img/lovarda/szentpeterpuszta06-11-15_7379_f-neubauer.webp", width: 2032, height: 1352 },
	{ src: "/img/lovarda/szentpeterpuszta06-11-15_7388_f-neubauer.webp", width: 2032, height: 1352 },
];


const LovardaFront = () => {

	const [index, setIndex] = useState(-1);

	return (
		<LovardaFrontStyled>
			<div className="content_inner">
				<h2 className="">Lovarda</h2>
				Kajászó-Szentpéterpuszta Lovarda Családias lovarda a budaörsi Tescotól 10 percre. 2472 Kajászó, Hidegvölgy utca 4.
				<br/><br/>
				<Link to="/lovarda"><InvertedButton>Tovább olvasom<img src='/Arrow.png' alt='Tovább olvasom'/></InvertedButton></Link>
				<br/>
			</div>
			
			<FirstRow>
				<PhotoAlbum layout="rows" photos={photos1} onClick={({ index }) => setIndex(index)} />
			</FirstRow>
			<SecondRow>
				<PhotoAlbum layout="rows" photos={photos2} onClick={({ index }) => setIndex(index +3 )} />
			</SecondRow>
			<Lightbox
				slides={[...photos1, ...photos2]}
				open={index >= 0}
				index={index}
				close={() => setIndex(-1)}
				// enable optional lightbox plugins
				plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
			/>
			
		</LovardaFrontStyled>
	)
}

export default LovardaFront;