import { Link } from 'react-router-dom';

import InvertedButton from '../../components/button/button.component';
import Hero from "../../components/hero/hero.component";
import LovardaFront from '../../components/lovarda-front/lovarda-front.component';
import Break from '../../components/break/break.component';
import BookCarousel from '../../components/books-front/books-front.component';


import { HomeDiv, Author, AuthorPortre, AuthorText } from "./home.styles";

const Home = () =>{
	return (
		<div>
			<Hero />
			<HomeDiv className="content_inner">
				<Author>
					
					<AuthorText>
						<AuthorPortre>
							<img src="/img/Boor_Goblyos_Istvan_portre.webp" alt="Boor Goblyos Istvan Portre"></img>
						</AuthorPortre>
						<h2>Dr. Boór-Gőblyös István</h2>
						<h3>lókiképző, lovasoktató</h3>
						<p>
							Dr. Boór-Gőblyös István  negyven éve foglalkozik
							lovakkal, tizenöt éve a Kajászó-Szentpéterpuszta Lovarda tulajdonosa, lovasedző, lókiképző.
						</p>
						<p>
							Dallos Gyula legidősebb tanítványa, díjlovaglásban, militaryban és díjugratásban versenyzett. 2014 óta az
							Academic Art of Ridingnak a tagja, 2013-tól vezeti saját iskoláját. 20 éve foglalkozik a lókiképzés
							elméletével, kutatja az elmúlt századok mestereinek lovaglási technikáját.
						</p>
						<p>
							Évek óta azon fáradozik, hogy újraélessze a magyar lókiképzés akadémista stílusát. Elkötelezett abban,
							hogy nemzeti hagyományainkat a XXI. századi lókiképzés részévé tegye, klasszikus lovas kultúránkat
							feltámassza.
						</p>
						<br/>
						<Link to="/szerzo"><InvertedButton>Tovább olvasom<img src='/Arrow.png' alt='Tovább olvasom' /></InvertedButton></Link>

					</AuthorText>
				</Author>
				
			</HomeDiv>
			<Break></Break>
			<BookCarousel></BookCarousel>
			<LovardaFront></LovardaFront>
		</div>
	
	);
}

export default Home;