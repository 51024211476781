import styled from "styled-components";

export const CartItemConatiner = styled.div`
	width: 100%;
	display: flex;
	height: 100px;
	margin-bottom: 15px;

	img {
		width: auto;
		height: auto;
		max-width: 132px;
	}
`;

export const ItemDetails = styled.div`
	width: 70%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: top;
	padding: 10px 20px;
	width: 260px;
	@media (max-width: 479px) {
		width: 240px;
	}
`;

export const Title = styled.span`


	font-family: 'Manuale';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 100%;
	color: #2D2D2D;
	max-width: 200px;


`
export const Quantity = styled.span`

	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;

	color: #4D4D4D;


`

export const Price = styled.span`

	font-family: 'Manuale';
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 100%;
	/* or 18px */
	text-align: right;
	
	color: #2D2D2D;
	position: relative;
	left: 200px;
	top: -33px;
	@media (max-width: 479px) {
		left: 0px;
		top: 14px;
	}


`