import { useState } from "react";


import Lightbox from "yet-another-react-lightbox";
// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import { LovardaHeader, LovardaBody } from "./lovarda.styles";



const photos = [
	{ src: "/img/lovarda/szentpeterpuszta06-11-15_7428_f-neubauer.webp", width: 2032, height: 1352 },
	{ src: "/img/lovarda/szentpeterpuszta06-11-15_7731_f-neubauer.webp", width: 2032, height: 1352 },
	{ src: "/img/lovarda/szentpeterpuszta06-11-15_7624_f-neubauer.webp", width: 2032, height: 1352 },
	{ src: "/img/lovarda/szentpeterpuszta06-11-15_7677_f-neubauer.webp", width: 2032, height: 1352 },
	{ src: "/img/lovarda/szentpeterpuszta06-11-15_7647_f-neubauer.webp", width: 2032, height: 1352 },
];

const Lovarda = () => {

	const [index, setIndex] = useState(-1);
	return (
		<div>
			<LovardaHeader>
				<img src="/img/lovarda/lovarda_header2.webp" alt="Kajászó-Szentpéterpuszta Lovarda" />
				
			</LovardaHeader>
			<LovardaBody className="content_inner">
				<h1>Kajászó-Szentpéterpuszta Lovarda</h1>
				<h2>
					Családias lovarda a budaörsi Tescotól 10 percre.
					2472 Kajászó, Hidegvölgy utca 4.
				</h2>
				

				<div className="container">
					<div className="box">
						<div className="box_image">
							<img src="/img/lovarda/szentpeterpuszta06-11-15_7428_f-neubauer_belyeg.webp" onClick={() => setIndex(0)} alt="" />
						</div>
						
						<div className="box_description">
							<ul>
								<li>
									Teljes körű szolgáltatás - mindent a lovakért és a lovasokért
								</li>
								<li>
									Külső pálya
								</li>
								<li>
									Díjugrató akadálypark
								</li>
								<li>
									Fix akadályok: szárazárkok, vizesárok, sövények, vágtapálya
								</li>
								<li>
									Fedett lovarda
								</li>
								
							</ul>
						</div>
					</div>
					<div className="box">
						<div className="box_image">
							<img src="/img/lovarda/szentpeterpuszta06-11-15_7731_f-neubauer_belyeg.webp" onClick={() => setIndex(1)} alt="" />
						</div>
						<div className="box_description">
							<ul>
								<li>
									Alacsony lószám - nincs téli lovardabeosztás
								</li>
								<li>
									EU szabványos, boxos istállózó lótartás
								</li>
								<li>
									EU szabványos külső nyitott angolboxok
								</li>
								<li>
									Rideg tartás
								</li>
								<li>
									Jártatógép
								</li>
	
							</ul>
						</div>
					</div>
					<div className="box">
						<div className="box_image">
							<img src="/img/lovarda/szentpeterpuszta06-11-15_7624_f-neubauer_belyeg.webp" onClick={() => setIndex(2)} alt="" />
						</div>
						<div className="box_description">
							<ul>
								<li>
									Lovak mozgatása napi két alkalommal (járhatógép, karámozás)
								</li>
								<li>
									Nagyméretű karámok
								</li>
								<li>
									Napi háromszori abrakolás (no limit zab-korpa, saját takarmány használata lehetséges)
								</li>
								<li>
									Magas minőségű széna, ad libitum mennyiségben
								</li>
								<li>
									Napi teljes almozás, folyamatos trágyaszedés
								</li>
								<li>
									Naponta ápolt pályák, locsolással
								</li>

							</ul>
						</div>
					</div>
					<div className="box">
						<div className="box_image">
							<img src="/img/lovarda/szentpeterpuszta06-11-15_7677_f-neubauer_belyeg.webp" onClick={() => setIndex(3)} alt="" />
						</div>
						<div className="box_description">
							<ul>
								<li>
									A fedett lovarda az istállóból zárt folyosón megközelíthető
								</li>
								<li>
									Nyitvatartás reggel 7-től este 8-ig
								</li>
								<li>
									Teherparkoló a lószállítók szármára
								</li>
								<li>
									Ládatárolók, raktárak a lovas felszereléseknek
								</li>
								<li>
									Nyergesek a lovas felszereléseknek
								</li>
								<li>
									Patamosó
								</li>
							</ul>
						</div>
					</div>
					<div className="box">
						<div className="box_image">
							<img src="/img/lovarda/szentpeterpuszta06-11-15_7647_f-neubauer_belyeg.webp" onClick={() => setIndex(4)} alt="" />
						</div>
						<div className="box_description">
							<ul>
								<li>
									Hagyományos, feszes tartástechnológia
								</li>
								<li>
									Barátságos lovagközösség, magas lovaskultúra, kimagasló versenyeredmények
								</li>
								<li>
									Klubhelyiség, öltöző, konyha, étkező
								</li>
								<li>
									Gyönyörű, parkosított környezet nyolc hektáron
								</li>
								<li>
									Kiváló talajú tereplovaglási lehetőségek
								</li>
								<li>
									Magas képzettségű, segítőkész, a lovak érdekeit szem előtt tartó lovászok
								</li>
							</ul>
						</div>
					</div>

				</div>

				<iframe title="gMap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7017.42958120353!2d18.7021879!3d47.330795099999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476a06223d76f587%3A0xea6987cb5e65206c!2sKaj%C3%A1sz%C3%B3-Szentp%C3%A9terpuszta%20Lovarda!5e1!3m2!1sen!2sro!4v1699428900334!5m2!1sen!2sro" 
				width="100%" height="350" allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
			</LovardaBody>
			<Lightbox
				slides={photos}
				open={index >= 0}
				index={index}
				close={() => setIndex(-1)}
				// enable optional lightbox plugins
				plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
			/>
		</div>


	)
}

export default Lovarda;