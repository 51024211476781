import styled from "styled-components";

export const Header = styled.div`
	padding: 10px;
	padding-left: 0px;
	button {
		width: auto;
		opacity: 1;
		bottom: 60%;
		position: absolute;
		left: 50%;
		line-height: 70px;
		height: 70px;
		margin-right: auto;
		margin-left: -110px;
		img{
			margin-top: 15px;
			width: 37px;
			height: 37px;
			margin-right: 15px;
			opacity: 1;
				-webkit-filter: invert(100%) !important;
		}
		@media (max-width: 500px) {
			min-width: 150px;
			padding: 0 20px;
		}
	}

	&:hover {
		img {
			opacity: 0.8;
		}

		button {
			opacity: 0.85;
			display: flex;
			
			&:hover{
				img{
					-webkit-filter: invert(0%) !important;
				}
			}
		}
	}
`

export const Footer = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	justify-content: space-between;
	font-size: 18px;
	border-right: 1px solid;
	padding-bottom: 80px;
	button{
		position: absolute;
		bottom: 0px;
		img{
			margin-top: 16px;
			margin-left: 5px;
			width: 29px;
			height: 16px;
		}
		&:hover{
			img{
				-webkit-filter: invert(100%) !important;
			}
		}
	}
	@media (max-width: 1100px) {
		border-right: none;
	}

`


export const BookCardContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-left: 30px;
	align-items: center;
	position: relative;
	overflow: hidden;
	button.add_to_cart{
		display: none;
	}
	 
	&:hover{
		button.add_to_cart{
			display: flex;
		}
	}
	img {
		width: 100%;
	}
	a{
		height: 100%;
	}
	&:last-of-type ${Footer}{
		border: none;
	}

`;




export const Title = styled.h2`
	width: 100%;
	text-align: left;
	margin-top: 20px;
	margin-bottom: 15px;
	font-family: 'Manuale';
	font-style: normal;
	font-weight: 700;
	font-size: 56px;
	line-height: 100%;
	color: #2D2D2D;
	@media (max-width: 1600px) {
		font-size: 40px;
	}


`
export const Price = styled.span`
	width: 10%;
`
