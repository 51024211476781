import { useContext } from "react";
import { BooksContext } from "../../contexts/books.context";
import BookCard from "../../components/book-card/book-card.component";
import {BooksContainer} from  './books.styles';

const Shop = () => {
	const { books } = useContext(BooksContext);
	return (
		<BooksContainer className="content_inner">
			{books.map((book) =>(
				<BookCard key={book.id} book={book} />
			))}
		</BooksContainer>
	)
}

export default Shop;