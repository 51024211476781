
import { Fragment, useContext } from 'react';
import { Outlet} from 'react-router-dom';

import CartIcon  from '../../components/cart-icon/cart-icon.component';
import CartDropdown from '../../components/cart-dropdown/cart-dropdown.component';
import CartDialogModal from '../../components/cart-dialog/cart-dialog.component';

import { CartContext } from '../../contexts/cart.context';

import {NavigationContainer, NavLinks, NavLink, LogoContainer,LogoTitle } from './navigation.styles';


const Navigation = () => {
	const {isCartOpen} = useContext(CartContext);
	return (
		<Fragment>
		<NavigationContainer className='content_inner'>
		
			<LogoContainer to='/'>
				<img src="Logo.png" alt="signature" />
					<LogoTitle>Dr. Boór-Gőblyös István</LogoTitle>
			</LogoContainer>
				
			<NavLinks>
				<NavLink to='/konyvek'>Könyvek</NavLink>
					<NavLink to='/szerzo'>A Szerzőről</NavLink>
				<NavLink to='https://zablaeskengyel.blog.hu/' target='_blank'>Blog</NavLink>
				<NavLink to='/lovarda'>Lovarda</NavLink>
				<CartIcon />
			</NavLinks>
			{isCartOpen && <CartDropdown />}
			<CartDialogModal/>
			</NavigationContainer>
			<Outlet />
		
		</Fragment>
	)
}

export default Navigation;